import styled from 'styled-components'

export const MainContainer = styled.main`
    //display:flex;
    //align-items:center;
    //justify-content:center;
`

export const Container = styled.div`
    //min-height:70%;
    //width:50%;
    max-width: 1200px;
    padding: 0px 20px 0px 20px;
    margin: 0 auto;
`

export const LogoContainer = styled.div`
    width:100%;
    max-width: 1200px;
    padding: 0px 0px 0px 20px;
    margin: 0 auto;
    height:4rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
`

export const Logo = styled.img`
    object-fit: cover;
`