import React from 'react';
import { RxCross2 } from "react-icons/rx";
import { useTranslation } from 'react-i18next';

import './SuccessfullMessage.css'

const SuccessfullMessage = ({ loading, setLoading }) => {
      const { t, i18n } = useTranslation();

    const onCloseHandler = () => {
        setLoading('new');
    };

    return (
        <div
            style={{ cursor: 'pointer' }}
            className={`successWrapper ${loading === 'approved' ? 'd-flex' : 'd-none'} align-items-center justify-content-center`}
            onClick={onCloseHandler}
        >
            <div className='container successCard text-center py-3'>
                <RxCross2
                    style={{ cursor: 'pointer' }}
                    className='fs-3 float-end cursor'
                    onClick={onCloseHandler}
                />
                <br />
                <h4 className='mt-2'>{t('RegistrationSuccessful')}</h4>
                <p>{t('DearValuedCustomer')}</p>
                <p>{t('EmailVerification')}</p>
                <p>{t('SpamAndJunk')}</p>
            </div>
        </div>
    )
};

export default SuccessfullMessage;
