import React from 'react';
import { Row, Col, FormGroup, Label, Input, Button } from 'reactstrap';

const BeneficialOwner = ({ beneficialOwner, setBeneficialOwner, validationErrors, userLogin, updateListFunction, imageList, setImageList }) => {

    const handleInputChange = (index, field, value) => {
        const updatedBeneficialOwner = [...beneficialOwner];
        updatedBeneficialOwner[index][field] = value;
        updatedBeneficialOwner[index].customerId = userLogin.id;
        setBeneficialOwner(updatedBeneficialOwner);
    };

    const addBeneficialOwner = () => {
        if (beneficialOwner.length < 3) {
            setBeneficialOwner([...beneficialOwner, { 
                title: '', 
                fullName: '', 
                jobTitle: '', 
                dob: '', 
                tin: '', 
                holdings: '', 
                proofOfIdentity: '', 
                proofOfIdentityBack: '', 
                resProofOfAddress: '' 
            }]);
        }
    };

    const removeBeneficialOwner = (index) => {
        // Remove beneficial owner data
        const updatedBeneficialOwner = beneficialOwner.filter((_, i) => i !== index);
        setBeneficialOwner(updatedBeneficialOwner);

        // Get the file keys associated with this beneficial owner
        const fileKeysToRemove = [
            `BENEFICIAL_PROOF_OF_ADDRESS_${index}`,
            `BENEFICIAL_PROOF_OF_ID_BACK_${index}`,
            `BENEFICIAL_RES_PROOF_OF_ADDRESS_${index}`
        ];

        // Filter out the removed beneficial owner's files from imageList
        const filteredImageList = imageList.filter(item => 
            !fileKeysToRemove.includes(item.key)
        );

        // Update indices for remaining files after the removed beneficial owner
        const updatedImageList = filteredImageList.map(item => {
            const [prefix, currentIndex] = item.key.split('_').slice(-1)[0].split('_');
            if (parseInt(currentIndex) > index) {
                // Decrease the index by 1 for all items after the removed beneficial owner
                return {
                    ...item,
                    key: `${prefix}-${parseInt(currentIndex) - 1}`
                };
            }
            return item;
        });

        // Update the imageList with all changes
        setImageList(updatedImageList);
    };

    const handleFileChange = (index, field, file) => {
        const maxFileSize = 5 * 1024 * 1024; // 5 MB
        const allowedExtensions = ['jpg', 'jpeg', 'png', 'pdf', 'heic'];
        const fileExtension = file.name.split('.').pop().toLowerCase();

        if (file.size > maxFileSize) {
            alert('File size exceeds the limit of 5 MB.');
            return false;
        } else if (!allowedExtensions.includes(fileExtension)) {
            alert('Please upload files with extension jpg, jpeg, png, pdf, heic.');
            return false;
        }
        return true;
    };

    return (
        <>
            <div className="w-100">
                <Row>
                    <Col md={12} className="d-flex align-items-center gap-2">
                        <h5>Ultimate Beneficial Owner</h5>
                        <Button color="primary" onClick={addBeneficialOwner}>+</Button>
                    </Col>
                </Row>
            </div>
            {beneficialOwner?.map((owner, index) => (
                <div key={index} className="justify w-100" style={{ marginBlock: "10px" }}>
                    {index > 0 && (
                        <Button color="danger" onClick={() => removeBeneficialOwner(index)}>-</Button>
                    )}
                    <Row>
                        <Col md={6}>
                            <FormGroup>
                                <Label>Title</Label>
                                <Input className={"form-control " + ((!validationErrors.step3.title) ? 'is-valid' : 'is-invalid')}
                                    type="text"
                                    value={owner.title}
                                    onChange={(e) => handleInputChange(index, 'title', e.target.value)}
                                    placeholder="Mr/Mrs"
                                    required
                                />
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <Label>Full Name</Label>
                                <Input className={"form-control " + ((!validationErrors.step3.fullName) ? 'is-valid' : 'is-invalid')}
                                    type="text"
                                    value={owner.fullName}
                                    onChange={(e) => handleInputChange(index, 'fullName', e.target.value)}
                                    placeholder="John"
                                    required
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <FormGroup>
                                <Label>Job Title</Label>
                                <Input className={"form-control " + ((!validationErrors.step3.jobTitle) ? 'is-valid' : 'is-invalid')}
                                    type="text"
                                    value={owner.jobTitle}
                                    onChange={(e) => handleInputChange(index, 'jobTitle', e.target.value)}
                                    placeholder="Manager"
                                    required
                                />
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <Label>Date Of Birth</Label>
                                <Input className={"form-control " + ((!validationErrors.step3.dob) ? 'is-valid' : 'is-invalid')}
                                    name="date"
                                    type="date"
                                    required
                                    placeholder="dd-mm-yyyy"
                                    value={owner.dob}
                                    onChange={(e) => handleInputChange(index, 'dob', e.target.value)}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <FormGroup>
                                <Label>TIN (If available)</Label>
                                <Input className={"form-control"}
                                    type="text"
                                    value={owner.tin}
                                    onChange={(e) => handleInputChange(index, 'tin', e.target.value)}
                                    placeholder="Tin"
                                    required
                                />
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <Label>Holdings (%)</Label>
                                <Input className={"form-control " + ((!validationErrors.step3.holdings) ? 'is-valid' : 'is-invalid')}
                                    type="text"
                                    value={owner.holdings}
                                    onChange={(e) => e.target.value <= 100 && handleInputChange(index, 'holdings', e.target.value)}
                                    placeholder="Enter holding (%) less then 100 or equal to 100 "
                                    required
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={4}>
                            <FormGroup>
                                <Label>Proof of Identity</Label>
                                <Input
                                    className={"form-control " + (!validationErrors.step3.proofOfIdentity ? 'is-valid' : 'is-invalid')}
                                    type="file"
                                    accept=".jpg, .jpeg, .png, .pdf, .heic"
                                    onChange={(e) => {
                                        if (e.target.files[0] && handleFileChange(index, 'proofOfIdentity', e.target.files[0])) {
                                            updateListFunction(`BENEFICIAL_PROOF_OF_ADDRESS_${index}`, [e.target.files[0]]);
                                            handleInputChange(index, 'proofOfIdentity', e.target.files[0]);
                                        }
                                    }}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={4}>
                            <FormGroup>
                                <Label>Proof of Identity Back</Label>
                                <Input
                                    type="file"
                                    accept=".jpg, .jpeg, .png, .pdf, .heic"
                                    onChange={(e) => {
                                        if (e.target.files[0] && handleFileChange(index, 'proofOfIdentityBack', e.target.files[0])) {
                                            updateListFunction(`BENEFICIAL_PROOF_OF_ID_BACK_${index}`, [e.target.files[0]]);
                                            handleInputChange(index, 'proofOfIdentityBack', e.target.files[0]);
                                        }
                                    }}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={4}>
                            <FormGroup>
                                <Label>Residential Proof of Address</Label>
                                <Input
                                    type="file"
                                    accept=".jpg, .jpeg, .png, .pdf, .heic"
                                    onChange={(e) => {
                                        if (e.target.files[0] && handleFileChange(index, 'resProofOfAddress', e.target.files[0])) {
                                            updateListFunction(`BENEFICIAL_RES_PROOF_OF_ADDRESS_${index}`, [e.target.files[0]]);
                                            handleInputChange(index, 'resProofOfAddress', e.target.files[0]);
                                        }
                                    }}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </div>
            ))}
        </>
    );
};

export default BeneficialOwner;