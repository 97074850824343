import React, { useState } from 'react';
import { IoMdArrowDropdown } from "react-icons/io";
import { useTranslation } from 'react-i18next';

const LanguageTranslation = ({ className }) => {
    const { i18n } = useTranslation();

    const [dropdownVisible, setDropdownVisible] = useState(false);
    const [languageName, setLanguageName] = useState(i18n.language === 'ar' ? 'Arabic' : i18n.language === 'fre' ? 'French' : 'English');

    const toggleDropdown = () => {
        setDropdownVisible(!dropdownVisible);
    };

    const languageSelectHandler = (lang) => {

        if (lang === 'en') {
            i18n.changeLanguage(lang);
            setLanguageName('English');
        } else if (lang === 'ar') {
            i18n.changeLanguage(lang);
            setLanguageName('Arabic');
        } else if (lang === 'fre') {
            i18n.changeLanguage(lang);
            setLanguageName('French');
        }

    };

    return (
        <div className={`dropdown`}>
            <button onClick={toggleDropdown} className={`dropbtn text-center`}>
                {/* <img src={imageFlag} alt='' width='28px' height='20px' className='me-2 rounded-1' /> */}
                {languageName}
                <IoMdArrowDropdown />
            </button>
            {dropdownVisible && (
                <div id="myDropdown1" className="dropdown-content">
                    <div className='d-flex align-items-center justify-content-center' onClick={() => { languageSelectHandler('en'); toggleDropdown() }}>
                        {/* <img src={britishFlag} alt='' width='28px' height='20px' className='me-2' /> */}
                        English
                    </div>
                    <div className='d-flex aling-items-center justify-content-center' onClick={() => { languageSelectHandler('ar'); toggleDropdown() }}>
                        {/* <img src={uaeFlag} alt='' width='25px' className='me-2' /> */}
                        Arabic
                    </div>
                    <div className='d-flex align-items-center justify-content-center' onClick={() => { languageSelectHandler('fre'); toggleDropdown() }}>
                        {/* <img src={franceFlag} alt='' width='28px' height='20px' className='me-2 rounded-1' /> */}
                        French
                    </div>
                </div>
            )}
        </div>
    )
}

export default LanguageTranslation
