import React, { useState } from 'react';
import { Col, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { ToastContainer } from "react-toastify";

import LanguageTranslation from './LanguageTranslation';
import { Container } from "./styled";
import { MoroccoForm } from './MoroccoForm';
import SuccssfullMessage from './SuccessfullMessage';
import lockImage from '../../template/images/Lock.png';
import loader from "../../assets/images/loader.gif";

import 'react-toastify/dist/ReactToastify.css';
import "./flags.css"
import FormTypes from './FormTypes';
import { CorporateForm } from '../coprateForm/CorporateForm';

const FormContainer = () => {
  const [loading, setLoading] = useState('new');
  const [formType, setFormType] = useState('Individual');
  const { t, i18n } = useTranslation();

  return (
    <div className={`d-flex align-items-center justity-content-center ${ formType === 'Individual' ? "IndividuallayoutContainer" : "CopratelayoutContainer" }`}>
      {loading === 'pending' ?
        <div id="global-loader1">
          <img src={loader} className="loader-img" alt="Loading...." />
        </div> :
        <div className='container rounded formContainer p-0 w-100'>
          <Row className="top_security w-100">
            <p style={{ marginBottom: '0px', fontSize: '13px' }} className={`top_sec_p d-flex align-items-center justify-content-center ${i18n.language === 'de' ? 'flex-row-reverse' : ''}`}>
              <img
                src={lockImage}
                style={{ marginTop: '-3px', width: '18px', marginRight: i18n.language !== 'de' ? '15px' : 0, marginLeft: i18n.language === 'de' ? '15px' : 0 }}
                alt="Lock Icon"
              />
              {t('Title')}
            </p>
          </Row>
          <Row style={{ alignItems: "center", margin: "5px" }}>
            <Col md={6} className='d-flex justify-content-center justify-content-md-start mb-3 mb-md-0'>
              <img src='/companyLogo_2.svg' alt='logo' className='mt-2' style={{ width: "70px", height: "70px" }} ></img>
            </Col>
            <Col md={6} className='d-flex justify-content-center justify-content-md-end gap-3'>
              <FormTypes className="logoCss" setFormType={setFormType} />
              { formType === 'Individual' && <LanguageTranslation className="logoCss" />}
            </Col>
          </Row>
          <Row className='mt-3 mt-lg-2 mx-0 w-100'>
            <h1 className='text-center secondary' >{t('ApplicationForm')}</h1>
            <p className='text-center primary fw-bold'>{t('Quote')}</p>
          </Row>
          <Container>
            { formType === 'Individual' ? <MoroccoForm setLoading={setLoading} loading={loading} /> : <CorporateForm setLoadingMain={setLoading} loadingMain={loading} />}
          </Container>
          <SuccssfullMessage loading={loading} setLoading={setLoading} />
        </div> 
      }
      <ToastContainer />
    </div>
  )
}

export default FormContainer
