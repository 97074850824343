import { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import { FormGroup, Row, Col, Label, Input } from 'reactstrap';
import { useForm } from "react-hook-form";
import { Button, CustomForm } from "../Form/style"
import '../Form/style.css';
import jsonData from '../../components/json/accountsType.json';
import countriesData from "../../components/json/countriesStates.json";
import { toast } from "react-toastify";
import { clientService, activitiesServices } from "../../services";
import countries from "../../components/json/resitrictedCountries.json";
import TokenService from "../../services/token.service";
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';
import { PiExclamationMarkDuotone } from "react-icons/pi";
import { useTranslation } from 'react-i18next';
import CryptoJS from "crypto-js";
import Select from 'react-select';

import '../../index.css';

const SECRET_KEY = process.env.REACT_APP_SECRET_KEY;
const decryptData = (ciphertext) => {
    const bytes = CryptoJS.AES.decrypt(ciphertext, SECRET_KEY);
    const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    return decryptedData;
};

export const MoroccoForm = ({ setLoading, loading }) => {
    const [refValue, setRefValue] = useState('');
    const [ptrValue, setPtrValue] = useState('');
    const [referrar, setReferrar] = useState('');
    const [agreementId, setAgreementId] = useState('');
    const [filteredAccountTypes, setFilteredAccountTypes] = useState('');
    const [emailError, setEmailError] = useState(0);
    const [imageList, setImageList] = useState([]);
    const [selectedCountryCode, setSelectedCountryCode] = useState('');
    const [countryList, setCountriesList] = useState([]);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [fileData, setFileData] = useState('');
    const [entityValue, setEntityValue] = useState(false);
    const [platForm, setPlatForm] = useState('MT4');
    const [utmMediumValue, setUtmMediumValue] = useState('');
    const [morocco, setMorocco] = useState(false);
    const [ipInfo, setIpInfo] = useState(null);
    const [email, setEmail] = useState({ type: '', value: '' });
    const [phoneError, setPhoneError] = useState(false);
    const [debouncedEmail, setDebouncedEmail] = useState('');
    const [errorMessage, setErrorMessage] = useState(false);
    const [utmArray, setUtmArray] = useState({});
    const navigate = useNavigate();
    const location = useLocation();
    const initialLoad = useRef(true);

    const { t, i18n } = useTranslation();

    const {
        setValue,
        reset,
    } = useForm();


    useEffect(() => {
        const fetchIPInfo = async () => {
            try {
                const response = await fetch('https://ipinfo.io/json?token=52a7645817b83a');
                const data = await response.json();
                setIpInfo(data);
            } catch (error) {
                console.error('Error fetching IP information:', error);
            }
        };

        fetchIPInfo();
    }, []);

    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        const refParam = searchParams.get('rm');
        const ptrParam = searchParams.get('ptr');
        let ibUrl = window.location.origin + `/?ptr=${ptrParam}&rm=${refParam}`;

        if (ibUrl === `${window.location.origin}/?ptr=2000095497&rm=69`) {
            window.location.href = `https://signup-ma.godocm.com/?rm=101&utm_medium=godoweb_ma`;
        }
    }, []);

    useEffect(() => {

        const searchParams = new URLSearchParams(window.location.search);
        const refParam = searchParams.get('rm');
        const ptrParam = searchParams.get('ptr');
        const refAddParam = searchParams.get('ref');
        const agreementParam = searchParams.get('agr');
        const entityParam = searchParams.get('entity');
        const platform = searchParams.get('platform');
        const partner = searchParams.get('partner');
        const nationality = searchParams.get('nationality');
        const utmSource = searchParams.get('utm_source');
        const utmCategory = searchParams.get('utm_category');
        const utm = searchParams.get('utm_medium');
        const utmCampaign = searchParams.get('utm_campaign');

        setUtmArray({
            utmCampaign: utmCampaign || '',
            utmCategory: utmCategory || '',
            utmSource: utmSource || '',
            utmMedium: utmMediumValue || utm || '',
        });

        if (entityParam === 'FSC') {
            const removeCountry = ['AFGHANISTAN', 'CUBA', 'GHANA', 'IRAN', 'LIBYA', 'MYANMAR', 'PANAMA', 'SOUNTH SUDAN', 'SYRIA', 'USA', 'YEMEN'];
            const filterCountry = countriesData.filter((country) => !removeCountry.includes(country.name));
            setCountriesList(filterCountry);
        } else if (entityParam === 'SCA') {
            const filterCountry = countriesData.filter((country) => country.name === "UNITED ARAB EMIRATES");
            setCountriesList(filterCountry);
        } else {
            setCountriesList(countriesData);
        }

        if (nationality === 'IRAN') {
            formData.step1.nationality = 'IRAN';
            formData.step1.nationalityCode = 'IR';
            formValueChange('step2', 'nationality', 'IRAN');
            formValueChange('step2', 'nationalityCode', 'IR');
        }

        if (nationality) {
            const formattedCountry = nationality.charAt(0) + nationality.slice(1).toLowerCase();
            const country = countries.find(country => country.name === formattedCountry);
            if (country) {
                formData.step1.country = nationality;
                formData.step1.countryCode = country.iso2;
                formValueChange('step1', 'country', nationality);
                formValueChange('step1', 'countryCode', country.iso2);
            }
        }

        setFilteredAccountTypes(filterAccountTypes('MT4'));
        // Parse the query parameters from the URL
        // Get the value of the 'ref' parameter
        if (partner === 'traderscaptain') {
            setRefValue('95');
            setPtrValue('2000111910');
        }

        if (entityParam) {
            setEntityValue(entityParam);
        }

        // mobile app url
        if (platform === 'MT5' && utm === 'godotrader_app' && entityParam === 'FSA' && (ipInfo !== null && ipInfo.country === 'MA')) {
            window.location.href = `https://signup-ma.godocm.com/?entity=FSA_MA&utm_medium=godotrader_app&platform=MT5`;
        }

        if (platform === 'MT5' && utm === 'godotrader_app' && entityParam === 'FSA_MA') {
            setPlatForm('MT5');
            setUtmMediumValue(utm);
            setMorocco(true);
            formData.step1.platform = 'MT5';
            formData.step1.utmMedium = utm;
            setFilteredAccountTypes(filterAccountTypes('MT5'));
            const filterCountry = countriesData.filter((country) => country.name === "MOROCCO");
            setCountriesList(filterCountry);
        }

        if (platform === 'MT5' && utm === 'godotrader_app' && entityParam === 'FSA') {
            setPlatForm('MT5');
            setUtmMediumValue(utm);
            formData.step1.platform = 'MT5';
            formData.step1.utmMedium = utm;
            setFilteredAccountTypes(filterAccountTypes('MT5'));
        }

        //desktop url
        if ((utm === 'godoweb_ma' && !platform)) {
            setUtmMediumValue(utm);
            setMorocco(true);
            formData.step1.utmMedium = utm;
            const filterCountry = countriesData.filter((country) => country.name === "MOROCCO");
            setCountriesList(filterCountry);
        }

        if (platform === 'MT5' && utm === 'godoweb_ma' && entityParam === 'FSA_MA') {
            setPlatForm('MT5');
            setUtmMediumValue(utm);
            setMorocco(true);
            formData.step1.platform = 'MT5';
            formData.step1.utmMedium = utm;
            setFilteredAccountTypes(filterAccountTypes('MT5'));
            const filterCountry = countriesData.filter((country) => country.name === "MOROCCO");
            setCountriesList(filterCountry);
        }

        // Set the value to the state
        if (refParam) {
            setRefValue(refParam);
        }

        if (refAddParam) {
            setReferrar(refAddParam);

            if (refAddParam === '98724816') {
                setFilteredAccountTypes(filterAccountTypes('MT4'));
                formData.step1.platform = 'MT4';
            }

        }
        if (agreementParam) {
            setAgreementId(agreementParam);
        }

        if (ptrParam) {
            setPtrValue(ptrParam);
        }

        // clientService.authorization().then((res) => {
        //     const token = res.token;
        //     const decryptToken = decryptData(token);
        //     TokenService.setUser(decryptToken);
        // }).catch((err) => {
        //     console.log('Error for auth is ', err);
        //     toast.error("Authorization error!");
        // });
        TokenService.setUser(process.env.REACT_APP_TOKEN);
    }, [morocco, utmMediumValue, ipInfo]);

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedEmail(email);  // Set the debounced email after delay
        }, 500);

        return () => {
            clearTimeout(handler);  // Clear the timeout if the user keeps typing
        };
    }, [email]);

    // Call the API when the debounced email changes (after typing stops)
    useEffect(() => {
        const checkEmailExistence = async () => {
            if (debouncedEmail) {
                try {
                    const res = await clientService.emailExist(debouncedEmail);
                    if (res.agentId) {
                        email.type === 'email' ? setErrorMessage(true) : setPhoneError(true);
                    } else {
                        email.type === 'email' ? setErrorMessage(false) : setPhoneError(false);
                    }
                } catch (error) {
                    console.error('Error checking email:', error);
                }
            }
        };

        checkEmailExistence();
    }, [debouncedEmail]);

    useEffect(() => {
        // Ensure 'ptr' is present on initial load
        if (initialLoad.current) {
            initialLoad.current = false;
            const searchParams = new URLSearchParams(location.search);

            // Check for required search parameters
            if (!searchParams.has('ptr') || !searchParams.has('rm') || !searchParams.has('nationality')) {
                const newUrl = `${location.pathname}?${searchParams.toString()}`;
                navigate(newUrl, { replace: true });
            } else if (searchParams.has('nationality') && searchParams.get('nationality') === 'MOROCCO') {
                // Redirect directly to the Moroccan URL without localhost prefix
                const newUrl = `https://signup-ma.godocm.com/?${searchParams.toString()}`;
                window.location.href = newUrl;
            }
        }
    }, [location, navigate]);

    const reloadHandler = (country) => {
        // Get current search params
        const searchParams = new URLSearchParams(location.search);
        searchParams.set('nationality', country);

        // Construct the new URL
        let newUrl;
        if (country === 'MOROCCO') {
            newUrl = `https://signup-ma.godocm.com/?${searchParams.toString()}`;
            window.location.href = newUrl;
        } else {
            newUrl = `${location.pathname}?${searchParams.toString()}`;
            // Push the new URL to the history
            navigate(newUrl);
        }
    };

    const [formData, setFormData] = useState({
        step1: { agentId: refValue, parentId: ptrValue, entity: entityValue ? entityValue : 'FSC', callStatus: 'New Lead', firstName: '', email: '', phone: '', accountType: '', accountTypeCurrency: '', password: '', confirmPassword: '', country: '', countryCode: '', platform: 'MT4', utmMedium: '' },
        step2: { nationality: '', nationalityCode: '', state: '', address: '', proofOfAddress: '', idType: '', idTypeCode: '', passportIdNo: '', year: '', month: '', day: '', proofOfIdentity: '', proofOfIdentityBack: '', residency: '', residencyCode: '', resProofOfAddress: '', annualIncome: '', netWorth: '', modeOfPayment: '', modeOfPaymentCode: '', sourceOfFunds: '', sourceOfFundsCode: '', sourceOfIncome: '', taxResidency: '', taxIdentificationNumber: '', additional: '' },
        step3: { experiencedTrading: false, sharesFrequency: '', cfdFrequency: '', powerOfAttorney: '', learn: true, accountTradedByOthers: false, acceptTradingTerms: false },
        step4: { usCitizen: false, politicallyExposed: false, beneficialOwnerCode: '', declaration: false }
        // Add more steps as needed
    });

    const data = {
        agentId: refValue || '33',
        parentId: ptrValue,
        entity: entityValue ? entityValue : 'FSC',
        firstName: formData.step1.firstName,
        email: formData.step1.email,
        platform: formData.step1.platform,
        accountType: formData.step1.accountType,
        accountTypeCurrency: formData.step1.accountTypeCurrency,
        country: formData.step1.country,
        countryCode: formData.step1.countryCode,
        phone: formData.step1.phone,
        ...utmArray,
        agreementId: agreementId,

        proofOfIdentity: formData.step2.proofOfIdentity,
        proofOfIdentityBack: formData.step2.proofOfIdentityBack,
        residency: formData.step2.residency,
        residencyCode: formData.step2.residencyCode,
        resProofOfAddress: formData.step2.resProofOfAddress,
        sourceOfFunds: formData.step2.sourceOfFunds,
        sourceOfFundsCode: formData.step2.sourceOfFundsCode,

        accountTradedByOthers: formData.step3.accountTradedByOthers,
        acceptTradingTerms: formData.step3.acceptTradingTerms,

        declaration: formData.step4.declaration,

        accountStatus: 'New',
        isLead: true,
        isClient: false,
    }

    const [validationErrors, setValidationErrors] = useState({
        step1: {
            firstName: false, phone: false, accountType: false, otpValue: false, country: false, countryCode: false, password: false, confirmPassword: false, platform: false
        },
        step2: {
            nationality: false, nationalityCode: false, address: false, proofOfAddress: false, idType: false, idTypeCode: false, passportIdNo: false, dob: false, day: false, month: false, year: false, proofOfIdentity: false, proofOfIdentityBack: false, residency: false, residencyCode: false, resProofOfAddress: false, annualIncome: false, netWorth: false, modeOfPayment: false, modeOfPaymentCode: false, sourceOfFunds: false, sourceOfFundsCode: false, source_Of_Income: false, taxResidency: false, taxIdentificationNumber: false, additional: false
        },
        step3: {
            experiencedTrading: false, sharesFrequency: false, cfdFrequency: false, learn: false, accountTradedByOthers: false, powerOfAttorney: false, acceptTradingTerms: false
        },
        step4: { usCitizen: true, politicallyExposed: true, beneficialOwnerCode: false, declaration: false }
        // other steps...
    });

    const formValueChange = (step, name, value) => {
        // Regular expression for English letters and basic punctuation
        const englishRegex = /^[a-zA-Z0-9\s.,'?!@#$%^&:*()_+=-]*$/;
        const uploadedFileNames = ['proofOfIdentity', 'proofOfIdentityBack', 'resProofOfAddress', 'sourceOfIncome'];

        if (name === 'email') {
            setEmail({ type: 'email', value: value });
            const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailPattern.test(value)) {
                setEmailError(1); // Set email error state if the email is invalid
            } else {
                setEmailError(0); // Clear email error state if the email is valid
            }
        }

        if (name === 'phone') {
            const phoneNumber = value.replace(/\s+/g, '')
            setEmail({ type: 'phone', value: phoneNumber });
            setFormData((prevFormData) => ({
                ...prevFormData,
                [step]: {
                    ...prevFormData[step],
                    [name]: value.replace(/[^\d]/g, ''),
                },
            }));
        } else if (englishRegex.test(value)) {
            setFormData((prevFormData) => ({
                ...prevFormData,
                [step]: {
                    ...prevFormData[step],
                    [name]: value,
                },
            }));
        } else if (uploadedFileNames.includes(name)) {
            setFormData((prevFormData) => ({
                ...prevFormData,
                [step]: {
                    ...prevFormData[step],
                    [name]: value,
                },
            }));
        }
    };


    const combineValidation = () => {
        const { firstName, email, phone, platform, accountType, country } = formData.step1;
        const { proofOfIdentity, proofOfIdentityBack, sourceOfFunds, resProofOfAddress } = formData.step2;
        const { acceptTradingTerms, accountTradedByOthers } = formData.step3;

        // Check each field individually and set their validity
        const isfirstNameValid = firstName.trim() !== '';
        const isEmailValid = email.trim() !== '';
        const isPhoneValid = phone.trim() !== '';
        const isPlatform = platform !== '';
        const isAccountTypeValid = accountType !== '';
        const isCountryValid = country !== '';
        const isSourceOfFunds = sourceOfFunds;
        const isProofOfIdentity = proofOfIdentity !== '';
        const isAcceptTradingTerms = acceptTradingTerms !== false;
        const isAccountTradedByOthers = accountTradedByOthers !== false;

        let documentUpload = false;
        const uploadedFiles = [proofOfIdentity, proofOfIdentityBack, resProofOfAddress];

        if (proofOfIdentity === '' && resProofOfAddress === '' ) {
            toast.error(`Please Upload the requested files`);
        }

        if (proofOfIdentity !== '') {
            documentUpload = true;
            for (let i = 0; i < uploadedFiles.length; i++) {
                for (let j = i + 1; j < uploadedFiles.length; j++) {
                    if (uploadedFiles[i] && uploadedFiles[i] === uploadedFiles[j]) {
                        toast.error(`Uploaded files could not be the same`);
                        return documentUpload = false;
                    }
                }
            }
        }

        // Set the validation errors dynamically for each field
        setValidationErrors((prevErrors) => ({
            ...prevErrors,
            step1: {
                ...prevErrors.step1,
                firstName: !isfirstNameValid,
                email: !isEmailValid,
                phone: !isPhoneValid,
                platform: !isPlatform,
                accountType: !isAccountTypeValid,
                country: !isCountryValid,
            },
            step2: {
                ...prevErrors.step2,
                sourceOfFunds: !isSourceOfFunds,
                proofOfIdentity: !isProofOfIdentity,
                resProofOfAddress: !resProofOfAddress
            },
            step3: {
                ...prevErrors.step3,
                acceptTradingTerms: !isAcceptTradingTerms,
                accountTradedByOthers: !isAccountTradedByOthers
            }
        }));




        // Return true if all fields are valid, false otherwise
        const validTrue = isfirstNameValid && isEmailValid && isPhoneValid && isAccountTypeValid && isCountryValid
            && documentUpload && isSourceOfFunds && isProofOfIdentity && isAcceptTradingTerms && isAccountTradedByOthers;

        if (!validTrue) {
            console.log('validation: ', validTrue);
            toast.error("Please fill all the fields!");
            return false;
        }
        return true;
    };

    const submitForm = async (e) => {
        e.preventDefault();

        if (errorMessage) {
            toast.error('Email Exist!');
        }

        if (phoneError) {
            toast.error('Phone Exist!');
        }

        if (combineValidation() && !errorMessage && !phoneError) {
            setLoading('pending');
            data.acceptedTerms = true;
            data.acceptedRisk = true;
            data.acceptedPrivacy = true;
            data.acceptedExecutionPolicy = true;
            data.acceptedConflicts = true;
            data.phone = phoneNumber !== undefined ? phoneNumber + data.phone.slice(1) : data.phone;

            const postClient = await clientService.postLead(data);
            console.log('postClinet: ', postClient);
            if (postClient && !postClient.data && postClient.length !== 0) {

                const formDataValue = new FormData();
                formDataValue.append('customerId', postClient.id);
                imageList.forEach((item) => {
                    formDataValue.append(item.key, item.value[0]); // Assuming each item contains only one file
                });

                const logData = {
                    type: 'userRegister',
                    customerId: postClient && postClient.id,
                    note: { 'note': ipInfo && JSON.stringify(ipInfo) + ` urlLink=${window.location.href}` }
                };

                activitiesServices.postActivity(logData);

                if (imageList.length !== 0) {
                    await clientService.uploadDocuments(formDataValue)
                }
                setLoading('approved');
                toast.success('Successfully Registered!');

            } else {
                console.log('error: ', postClient)
                setLoading('new');
                toast.error(postClient.data.message);
            }

        }
    };

    const updateListFunction = (key, files) => {

        // Check if the key already exists in the imageList
        const existingIndex = imageList.findIndex(entry => entry.key === key);

        if (existingIndex !== -1) {
            // If the key already exists, update its value
            const updatedList = [...imageList];
            updatedList[existingIndex].value = files;

            setImageList(updatedList);
            console.log('file: ', imageList)
        } else {
            // If the key does not exist, add it as a new entry
            const newValue = {
                key,
                value: files
            };

            const updatedList = [...imageList, newValue];
            setImageList(updatedList);
            console.log('file: ', imageList)
        }
    };

    const filterAccountTypes = (platform) => {
        if (ptrValue && refValue !== '38' && !morocco) {
            return jsonData.filter(item => (item['TP platform'] === platform && item['ib'] === 1))
        } else if (ptrValue && refValue !== '38' && morocco) {
            return jsonData.filter(item => (item['TP platform'] === platform && item['ib'] === 3))
        } else if (refValue === '38') {
            return jsonData.filter(item => (item['TP platform'] === platform && item['Server Number'] === 2))
        } else if (morocco) {
            return jsonData.filter(item => (item['TP platform'] === platform && item['Server Number'] === 3))
        } else if (referrar === '98724816') {
            return jsonData.filter(item => (item['TP platform'] === platform && item['Server Number'] === 1))
        } else {
            return jsonData.filter(item => (item['TP platform'] === platform && item['Server Number'] === 1))
        }
    };

    const accountTypeValue = (platform) => {
        // Determine accountType based on platform
        const accountType = (morocco === false
            ? (platform === 'MT5' ? '2' : '14')
            : (platform === 'MT5' ? '42' : '38'));

        // Update form data
        setFormData((prevFormData) => ({
            ...prevFormData,
            step1: {
                ...prevFormData.step1,
                accountType,
                accountTypeCurrency: 'USD'
            },
        }));

        console.log("Account Type Setting", formData.step1);
    }

    const handleChange = (selectedOption) => {
        const country = selectedOption ? selectedOption.value : null;

        if (country) {
            const selectedCountryData = countriesData.find(item => item.name === country);
            if (selectedCountryData) {

                if (selectedCountryData.name === 'MOROCCO') {
                    reloadHandler('MOROCCO');
                }

                setSelectedCountryCode(selectedCountryData.iso2.toLowerCase());
                formValueChange('step1', 'country', selectedCountryData.name);
                formValueChange('step1', 'countryCode', selectedCountryData.iso2);
                formValueChange('step2', 'residency', selectedCountryData.name);
                formValueChange('step2', 'residencyCode', selectedCountryData.iso2);
            }
        }
    };

    const countryOptions = countryList.map(item => ({
        value: item.name,
        label: item.name
    }));

    return (
        <div className="formBody" style={{ fontSize: i18n.language === 'ar' || i18n.language === 'fe' ? '18px' : '' }}>
            <div id="recaptcha-container" style={{ display: 'none' }}></div> <>
                <CustomForm>
                    <div className="justify mt-2">
                        <Row className={`${(i18n.language === 'ar' || i18n.language === 'fe') ? 'flex-row-reverse text-end' : ''}`}>
                            <Col md={6}>
                                <FormGroup>
                                    <Label>{t('FullName')}</Label>
                                    <div className="input-group">
                                        <Input className={"form-control " + ((!validationErrors.step1.firstName || formData.step1.firstName) ? 'is-valid' : 'is-invalid')}
                                            name="firstName" type="text"
                                            required="" placeholder="John"
                                            value={formData.step1.firstName}
                                            onChange={(e) => {
                                                // Remove non-alphanumeric characters except spaces
                                                let cleanedInput = e.target.value.replace(/[^a-zA-Z0-9 ]/g, '');

                                                // Split the input into words, capitalize the first letter of each word, and join them back
                                                let capitalizedInput = cleanedInput.split(' ')
                                                    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                                                    .join(' ');

                                                // Update the form value
                                                formValueChange('step1', 'firstName', capitalizedInput);
                                            }}
                                        />
                                    </div>
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label>{t('EmailAddress')}</Label>
                                    <div className="input-group">
                                        <Input className={"form-control " + (!validationErrors.step1.email || formData.step1.email ? 'is-valid' : 'is-invalid')}
                                            type="email"
                                            name="email"
                                            validation={{ required: true }}
                                            value={formData.step1.email}
                                            placeholder="john@gmail.com"
                                            invalid={emailError === 1 && formData.step1.email !== ''} // Invalid if emailError is 1 and email is not empty
                                            valid={emailError === 0 && formData.step1.email !== ''} // Valid if emailError is 0 and email is not empty
                                            onChange={(e) => formValueChange('step1', e.target.name, e.target.value)}
                                        />
                                    </div>
                                    {errorMessage && <span className="text-danger" >Email Already Exist</span>}
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row className={`${(i18n.language === 'ar' || i18n.language === 'fe') ? 'flex-row-reverse text-end' : ''}`}>
                            <Col md={12}>
                                <Row className={`${(i18n.language === 'ar' || i18n.language === 'fe') ? 'flex-row-reverse text-end' : ''}`}>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label>{t('CountryOfResidence')}</Label>
                                            <Select
                                                className={`mb-3 ${!validationErrors.step1.country || formData.step1.country ? 'is-valid' : 'is-invalid'}`}
                                                name="country"
                                                options={countryOptions}
                                                value={countryOptions.find(option => option.value === formData.step1.country)}
                                                onChange={handleChange}
                                                placeholder="-- Select --"
                                                required
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md={6} className="phoneNum">
                                        <Label>{t('PhoneNumber')}</Label>
                                        <IntlTelInput
                                            name="phone"
                                            containerClassName="intl-tel-input"
                                            inputClassName={"form-control " + ((!validationErrors.step1.phone || formData.step1.phone) ? 'is-valid' : 'is-invalid')}
                                            style={{ width: '100%' }}
                                            maxLength={15}
                                            format="true"
                                            defaultCountry={selectedCountryCode || 'ae'}
                                            useMobileFullscreenDropdown={false}
                                            value={formData.step1.phone} // Ensure the value is set correctly
                                            // onSelectFlag={(selectedCountryData, fullNumber, isvalid, currentNumber) => {
                                            //     setCountryid(fullNumber && fullNumber.iso2 && fullNumber.iso2.toUpperCase());
                                            // }}
                                            onPhoneNumberChange={(status, value, countryData, number, id) => {
                                                setPhoneNumber(countryData.dialCode);
                                                formValueChange('step1', 'phone', value);
                                            }}
                                        />
                                        {phoneError && <span className="text-danger" >Phone Already Exist</span>}
                                    </Col>
                                    <Col md={4}>
                                        <FormGroup>
                                            <Label>{t("Profession")}</Label>
                                            <div className="input-group">
                                                <Input
                                                    className={((!validationErrors.step2.sourceOfFunds || formData.step2.sourceOfFunds) ? 'is-valid' : 'is-invalid')}
                                                    type="select"
                                                    name="sourceOfFunds"
                                                    value={formData.step2.sourceOfFunds}
                                                    required=""
                                                    onChange={(e) => {
                                                        if (e.target.value !== '') {
                                                            formValueChange('step2', e.target.name, e.target.value)
                                                            if (e.target.value === 'Salaried') {
                                                                formValueChange('step2', 'sourceOfFundsCode', 'SAL')
                                                            } else if (e.target.value === 'Self-Employed') {
                                                                formValueChange('step2', 'sourceOfFundsCode', 'SLF')
                                                            } else if (e.target.value === 'Student') {
                                                                formValueChange('step2', 'sourceOfFundsCode', 'STU')
                                                            } else if (e.target.value === 'Freelancer') {
                                                                formValueChange('step2', 'sourceOfFundsCode', 'FRE')
                                                            } else if (e.target.value === 'Management') {
                                                                formValueChange('step2', 'sourceOfFundsCode', 'MAN')
                                                            } else {
                                                                formValueChange('step2', 'sourceOfFundsCode', 'UNE')
                                                            }
                                                        }
                                                    }}
                                                >
                                                    <option value="">-- Select --</option>
                                                    <option value="Salaried">Salaried</option>
                                                    <option value="Self-Employed">Self-Employed</option>
                                                    <option value="Student">Student</option>
                                                    <option value="Freelancer">Freelancer</option>
                                                    <option value="Management">Management</option>
                                                    <option value="Unemployed">Unemployed</option>
                                                </Input>
                                            </div>
                                        </FormGroup>
                                    </Col>
                                    <Col md={4}>
                                        <FormGroup>
                                            <Label>{t('Platform')}</Label>
                                            <div className="input-group">
                                                <Input
                                                    type="select"
                                                    name="platform"
                                                    className={((!validationErrors.step1.platform || formData.step1.platform) ? 'is-valid' : 'is-invalid')}
                                                    value={formData.step1.platform}
                                                    onChange={(e) => {
                                                        formValueChange('step1', e.target.name, e.target.value);
                                                        setFilteredAccountTypes(filterAccountTypes(e.target.value));
                                                        accountTypeValue(e.target.value);
                                                    }}
                                                >
                                                    {
                                                        referrar === '98724816' ? (
                                                            <option value="MT4">MT4</option>
                                                        ) : (
                                                            <>
                                                                {platForm === 'MT4' && <option value="MT4">MT4</option>}
                                                                <option value="MT5">MT5</option>
                                                                {/* <option value="GTN">GODO Invest</option> */}
                                                                {!ptrValue && <option value="cTrader">cTrader</option>}
                                                            </>
                                                        )
                                                    }
                                                </Input>
                                            </div>
                                        </FormGroup>
                                    </Col>
                                    <Col md={4}>
                                        <FormGroup>
                                            <Label>{t('AccountType')}</Label>
                                            <div className="input-group">
                                                <Input
                                                    type="select"
                                                    name="accountType"
                                                    className={!validationErrors.step1.accountType || formData.step1.accountType ? 'is-valid' : 'is-invalid'}
                                                    value={formData.step1.accountType}
                                                    onChange={(e) => {
                                                        if (e.target.value !== '') {
                                                            formValueChange('step1', e.target.name, e.target.value);
                                                            const accountCurrency = filteredAccountTypes.filter((account) => account.Id === parseInt(e.target.value));
                                                            formValueChange('step1', 'accountTypeCurrency', accountCurrency[0].Currency);
                                                        }
                                                    }}
                                                >
                                                    <option value="">-- Select --</option>
                                                    {
                                                        referrar === '98724816' ? (
                                                            <option value="55">MANAGED</option>
                                                        ) : (
                                                            filteredAccountTypes &&
                                                            filteredAccountTypes.map((item, index) => (
                                                                <option key={index} value={item['Id']}>
                                                                    {item['Account type']}
                                                                </option>
                                                            ))
                                                        )
                                                    }
                                                </Input>
                                            </div>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row className={`${(i18n.language === 'ar' || i18n.language === 'fe') ? 'flex-row-reverse text-end' : ''}`}>
                            <Col md={4}>
                                <Label for="proofOfIdentity" className="text-primary fw-bold"> {t('ProofOfIdentity')}
                                    <div className="tooltip" style={{ opacity: '1', position: 'relative', top: '-3px' }}><PiExclamationMarkDuotone style={{ rotate: '180deg' }} />
                                        <span className="tooltiptext">Please provide at least <b>ONE</b> of the following documents that
                                            MUST have legal name, Not expired, date of birth, nationality, & registered ID number:
                                            <ul>
                                                <li>Passport - For any Nationality </li>
                                                <li>Emirates ID, in case the client is UAE Resident </li>
                                                <li>Any other Government Issued ID which has these credentials</li>
                                            </ul>
                                        </span>
                                    </div>
                                </Label>
                                <Input
                                    className={"mb-3 mb-md-0 " + ((!validationErrors.step2.proofOfIdentity || formData.step2.proofOfIdentity) ? 'is-valid' : 'is-invalid')}
                                    type="file"
                                    name="proofOfIdentity"
                                    id="proofOfIdentity"
                                    accept=".jpg, .jpeg, .png, .pdf, .heic"
                                    onChange={(e) => {
                                        const maxFileSize = 5 * 1024 * 1024; // 2 MB in bytes

                                        const fileExtension = ['jpg', 'jpeg', 'png', 'pdf', 'heic'];
                                        const fileName = e.target.value;
                                        const file = e.target.files[0];
                                        const fileExtensionCheck = fileName.split('.').pop().toLowerCase();

                                        if (file) {
                                            if (file.size > maxFileSize) {
                                                alert('File size exceeds the limit of 5 MB.');
                                                e.target.value = '';
                                                formValueChange('step2', e.target.name, e.target.value);
                                            } else if (fileExtension.includes(fileExtensionCheck)) {
                                                setFileData(e.target.value);
                                                updateListFunction('PROOF_OF_ID', [e.target.files[0]]);
                                                formValueChange('step2', e.target.name, e.target.value);
                                            } else {
                                                alert('Please Upload files with extension jpg, jpeg, png, pdf, heic');
                                                e.target.value = '';
                                                formValueChange('step2', e.target.name, e.target.value);
                                            }
                                        }
                                    }}
                                />
                            </Col>
                            <Col md={4}>
                                <Label for="proofOfIdentityBack" className="text-primary fw-bold">{t('ProofOfIdentityBack')}
                                </Label>
                                <Input
                                    className={"mb-3 mb-md-0 "}
                                    type="file"
                                    name="proofOfIdentityBack"
                                    id="proofOfIdentityBack"
                                    accept=".jpg, .jpeg, .png, .pdf, .heic"
                                    onChange={(e) => {
                                        const maxFileSize = 5 * 1024 * 1024; // 2 MB in bytes

                                        const fileExtension = ['jpg', 'jpeg', 'png', 'pdf', 'heic'];
                                        const fileName = e.target.value;
                                        const file = e.target.files[0];
                                        const fileExtensionCheck = fileName.split('.').pop().toLowerCase();

                                        if (file) {
                                            if (file.size > maxFileSize) {
                                                alert('File size exceeds the limit of 5 MB.');
                                                e.target.value = '';
                                                formValueChange('step3', e.target.name, e.target.value);
                                            } else if (fileExtension.includes(fileExtensionCheck)) {
                                                setFileData(e.target.value);
                                                updateListFunction('PROOF_OF_ID_BACK', [e.target.files[0]]);
                                                formValueChange('step2', e.target.name, e.target.value);
                                            } else {
                                                alert('Please Upload files with extension jpg, jpeg, png, pdf, heic');
                                                e.target.value = '';
                                                formValueChange('step2', e.target.name, e.target.value);
                                            }
                                        }
                                    }}
                                />
                            </Col>
                            <Col md={4}>
                                <Label className="text-primary fw-bold"> {t('ProofOfAddress')}
                                    <div className="tooltip" style={{ opacity: '1', zIndex: '5', position: 'relative', top: '-3px' }}><PiExclamationMarkDuotone style={{ rotate: '180deg' }} />
                                        <span className="tooltiptextRight">Please provide at least ONE of the following documents that
                                            MUST state <b>current or permanent residential address as per Application form</b> and have been issued within the last 3 calendar months
                                            <ul>
                                                <li>Utility Bills or statements from public utilities, including electricity, water, gas, or telephone line providers  </li>
                                                <li>Lease / Tenancy Agreement  (MUST be within the lease term) </li>
                                                <li>Local and national government-issued documents, including municipal tax records or similar</li>
                                                <li>Registered property purchase, lease or rental agreements</li>
                                                <li>Documents from supervised third-party financial institutions, such as bank statements or insurance policies, alike.</li>
                                                <li>Incase the client is sharing a bedspace or on a short term rent, may provide permenant residence document of his/her nationality.</li>
                                            </ul>
                                        </span>
                                    </div>
                                </Label>
                                <div className="input-group mb-1">
                                    <Input
                                        className={"mb-3 mb-md-0 form-control " + ((!validationErrors.step2.resProofOfAddress || formData.step2.resProofOfAddress) ? 'is-valid' : 'is-invalid')}
                                        name="resProofOfAddress"
                                        type="file"
                                        accept=".jpg, .jpeg, .png, .pdf, .heic"
                                        onChange={(e) => {

                                            const maxFileSize = 5 * 1024 * 1024; // 2 MB in bytes

                                            const fileExtension = ['jpg', 'jpeg', 'png', 'pdf', 'heic'];
                                            const fileName = e.target.value;
                                            const file = e.target.files[0];
                                            const fileExtensionCheck = fileName.split('.').pop().toLowerCase();

                                            if (file) {
                                                if (file.size > maxFileSize) {
                                                    alert('File size exceeds the limit of 5 MB.');
                                                    e.target.value = '';
                                                    formValueChange('step3', e.target.name, e.target.value);
                                                } else if (fileExtension.includes(fileExtensionCheck)) {
                                                    setFileData(e.target.value);
                                                    updateListFunction('RES_PROOF_OF_ADDRESS', [e.target.files[0]]);
                                                    formValueChange('step2', e.target.name, e.target.value);
                                                } else {
                                                    alert('Please Upload files with extension jpg, jpeg, png, pdf, heic');
                                                    e.target.value = '';
                                                    formValueChange('step2', e.target.name, e.target.value);
                                                }
                                            }
                                        }}
                                    />
                                </div>
                            </Col>
                        </Row>
                        <Row className={`${(i18n.language === 'de' || i18n.language === 'fa') ? 'flex-row-reverse text-end' : ''} ${(!validationErrors.step3.accountTradedByOthers || formData.step3.accountTradedByOthers) ? 'text-black' : 'text-danger'} mt-4`}>
                            <Col md={12} className={`d-flex ${(i18n.language === 'ar') ? 'flex-row-reverse text-end' : 'justify-content-start'} `}>
                                <div className="input-group mb-3 w-auto">
                                    <div className="form-check form-check-inline mx-1">
                                        <Input className="form-check-input"
                                            type="checkbox"
                                            name="accountTradedByOthers"
                                            id="accountTradedByOthers"
                                            value={formData.step3.accountTradedByOthers}
                                            checked={formData.step3.accountTradedByOthers}
                                            onChange={(e) => formValueChange('step3', e.target.name, e.target.checked)} />
                                    </div>
                                </div>
                                <Label>
                                    {t('Agreement')}
                                    <a style={{ wordSpacing: "-1px", padding: "0px 6px" }} href="https://godocm.com/fr/limited-power-of-attorney-account-manager" alt="" target="_blank" rel="noreferrer">{t('PowerofAttorney')}</a>
                                </Label>
                            </Col>
                            <Col md={12} className={`d-flex ${(i18n.language === 'ar') ? 'flex-row-reverse text-end' : 'justify-content-start'} ${(!validationErrors.step3.acceptTradingTerms || formData.step3.acceptTradingTerms) ? 'text-black' : 'text-danger'} `}>
                                <div className="input-group mb-3 w-auto">
                                    <div className="form-check form-check-inline mx-1">
                                        <Input className="form-check-input"
                                            type="checkbox"
                                            name="acceptTradingTerms"
                                            id="acceptTradingTerms"
                                            value={formData.step3.acceptTradingTerms}
                                            checked={formData.step3.acceptTradingTerms}
                                            onChange={(e) => formValueChange('step3', e.target.name, e.target.checked)} />
                                    </div>
                                </div>
                                <Label>
                                    {t('Approve')}
                                    <a style={{ wordSpacing: "-1px", padding: "0px 6px" }} href="https://www.godocm.com/wp-content/uploads/2025/01/Terms-of-Business.pdf" alt="" target="_blank" rel="noreferrer">{t('TermsofBusiness')}</a>
                                    {t('Approve2')}
                                </Label>
                            </Col>
                        </Row>
                    </div>
                    <div>
                        <Button type="submit" onClick={(e) => submitForm(e)} className='primary-bg-color col buttonWidth rounded' style={{ height: '50px', background: '#26539F' }}>
                            {t('RegisterNow')}
                        </Button>
                    </div>
                </CustomForm>
            </>
        </div>
    )
}
