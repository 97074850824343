import React from 'react';
//import ReactDOM from 'react-dom/client';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import SCA from './containers/NewForm/FormContainer';
import './index.css';
import reportWebVitals from './reportWebVitals';

import './config/i18n';
import EmailVerification from './containers/NewForm/EmailVerification/EmailVerification';

const root = createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>
      <Routes>
        <Route path={`${process.env.PUBLIC_URL}/`} element={<SCA />} />
        <Route path={`${process.env.PUBLIC_URL}/emailVerified`} element={<EmailVerification />} />
      </Routes>
    </Router>
  </React.StrictMode>
);

reportWebVitals();
