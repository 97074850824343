import React from 'react';
import { Row, Col, FormGroup, Label, Input, Button } from 'reactstrap';

const DirectorDetails = ({ directors, setDirectors, validationErrors, userLogin, updateListFunction, imageList, setImageList }) => {

    const handleInputChange = (index, field, value) => {
        const updatedDirectors = [...directors];
        updatedDirectors[index][field] = value;
        updatedDirectors[index].customerId = userLogin.id;
        setDirectors(updatedDirectors);
    };

    const addDirector = () => {
        if (directors.length < 3) {
            setDirectors([...directors, { fullName: '', phoneNumber: '', address: '', tin: '', proofOfIdentity: '', proofOfIdentityBack: '', resProofOfAddress: '' }]);
        }
    };

    const removeDirector = (index) => {
        // Remove director data
        const updatedDirectors = directors.filter((_, i) => i !== index);
        setDirectors(updatedDirectors);

        // Get the file keys associated with this director
        const fileKeysToRemove = [
            `DIRECTOR_PROOF_OF_ADDRESS_${index}`,
            `DIRECTOR_PROOF_OF_ID_BACK_${index}`,
            `DIRECTOR_RES_PROOF_OF_ADDRESS_${index}`
        ];

        // Filter out the removed director's files from imageList
        const filteredImageList = imageList.filter(item => 
            !fileKeysToRemove.includes(item.key)
        );

        // Update indices for remaining files after the removed director
        const updatedImageList = filteredImageList.map(item => {
            const [prefix, currentIndex] = item.key.split('_').slice(-1)[0].split('_');
            if (parseInt(currentIndex) > index) {
                // Decrease the index by 1 for all items after the removed director
                return {
                    ...item,
                    key: `${prefix}-${parseInt(currentIndex) - 1}`
                };
            }
            return item;
        });

        // Update the imageList with all changes
        setImageList(updatedImageList);
    };

    const handleFileChange = (index, field, file) => {
        const maxFileSize = 5 * 1024 * 1024; // 5 MB
        const allowedExtensions = ['jpg', 'jpeg', 'png', 'pdf', 'heic'];
        const fileExtension = file.name.split('.').pop().toLowerCase();

        if (file.size > maxFileSize) {
            alert('File size exceeds the limit of 5 MB.');
            return false;
        } else if (!allowedExtensions.includes(fileExtension)) {
            alert('Please upload files with extension jpg, jpeg, png, pdf, heic.');
            return false;
        }
        return true;
    };

    return (
        <>
            <div className="w-100">
                <Row>
                    <Col md={12} className="d-flex align-items-center gap-2">
                        <h5>Please list all directors of the Company (or equivalent).</h5>
                        <Button color="primary" onClick={addDirector}>+</Button>
                    </Col>
                </Row>
            </div>
            {directors?.map((director, index) => (
                <div key={index} className="justify w-100" style={{ marginBlock: "10px" }}>
                    {index > 0 && (
                        <Button color="danger" onClick={() => removeDirector(index)}>-</Button>
                    )}
                    <Row>
                        <Col md={6}>
                            <FormGroup>
                                <Label>Full Name</Label>
                                <Input className={"form-control " + ((!validationErrors.step2.fullName) ? 'is-valid' : 'is-invalid')}
                                    type="text"
                                    value={director.fullName}
                                    onChange={(e) => handleInputChange(index, 'fullName', e.target.value)}
                                    placeholder="John"
                                    required
                                />
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <Label>Contact Number</Label>
                                <Input className={"form-control " + ((!validationErrors.step2.phone) ? 'is-valid' : 'is-invalid')}
                                    type="text"
                                    value={director.phone}
                                    onChange={(e) => handleInputChange(index, 'phoneNumber', e.target.value)}
                                    placeholder="050 123 4567"
                                    required
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <FormGroup>
                                <Label>Residential Address</Label>
                                <Input className={"form-control " + ((!validationErrors.step2.address) ? 'is-valid' : 'is-invalid')}
                                    type="text"
                                    value={director.address}
                                    onChange={(e) => handleInputChange(index, 'address', e.target.value)}
                                    placeholder="House No - Street - City - State"
                                    required
                                />
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <Label>TIN</Label>
                                <Input className={"form-control " + ((!validationErrors.step2.tin) ? 'is-valid' : 'is-invalid')}
                                    type="text"
                                    value={director.tin}
                                    onChange={(e) => handleInputChange(index, 'tin', e.target.value)}
                                    placeholder="Tin"
                                    required
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={4}>
                            <FormGroup>
                                <Label>Proof of Identity</Label>
                                <Input
                                    className={"form-control " + (!validationErrors.step2.proofOfIdentity ? 'is-valid' : 'is-invalid')}
                                    type="file"
                                    accept=".jpg, .jpeg, .png, .pdf, .heic"
                                    onChange={(e) => {
                                        if (e.target.files[0] && handleFileChange(index, 'proofOfIdentity', e.target.files[0])) {
                                            updateListFunction(`DIRECTOR_PROOF_OF_ADDRESS_${index}`, [e.target.files[0]]);
                                            handleInputChange(index, 'proofOfIdentity', e.target.files[0]);
                                        }
                                    }}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={4}>
                            <FormGroup>
                                <Label>Proof of Identity Back</Label>
                                <Input
                                    type="file"
                                    accept=".jpg, .jpeg, .png, .pdf, .heic"
                                    onChange={(e) => {
                                        if (e.target.files[0] && handleFileChange(index, 'proofOfIdentityBack', e.target.files[0])) {
                                            updateListFunction(`DIRECTOR_PROOF_OF_ID_BACK_${index}`, [e.target.files[0]]);
                                            handleInputChange(index, 'proofOfIdentityBack', e.target.files[0]);
                                        }
                                    }}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={4}>
                            <FormGroup>
                                <Label>Residential Proof of Address</Label>
                                <Input
                                    type="file"
                                    accept=".jpg, .jpeg, .png, .pdf, .heic"
                                    onChange={(e) => {
                                        if (e.target.files[0] && handleFileChange(index, 'resProofOfAddress', e.target.files[0])) {
                                            updateListFunction(`DIRECTOR_RES_PROOF_OF_ADDRESS_${index}`, [e.target.files[0]]);
                                            handleInputChange(index, 'resProofOfAddress', e.target.files[0]);
                                        }
                                    }}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </div>
            ))}
        </>
    );
};

export default DirectorDetails;