import React, { useState } from 'react';
import { Row, Col, FormGroup, Label, Input, Button } from 'reactstrap';

const AuthorizedSignatory = ({
    authorizedSignatory, setAuthorizedSignatory, validationErrors, userLogin, updateListFunction, imageList, setImageList }) => {
    
    const [emailError, setEmailError] = useState(0);    

    const validateEmail = (email) => {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        setEmailError(emailPattern.test(email) ? 0 : 1);
    };

    const handleInputChange = (index, field, value) => {

        if (field === 'email') {
            validateEmail(value);
        }

        const updatedAuthorizedSignatory = [...authorizedSignatory];
        updatedAuthorizedSignatory[index][field] = value;
        updatedAuthorizedSignatory[index].customerId = userLogin.id;
        setAuthorizedSignatory(updatedAuthorizedSignatory);
    };

    const addAuthorizedSignatory = () => {
        if (authorizedSignatory.length < 3) {
            setAuthorizedSignatory([...authorizedSignatory, { fullName: '', jobTitle: '', email: '', proofOfIdentity: '', proofOfIdentityBack: '', resProofOfAddress: '' }]);
        }
    };

    const removeAuthorizedSignatory = (index) => {
        // Remove authorized signatory data
        const updatedAuthorizedSignatory = authorizedSignatory.filter((_, i) => i !== index);
        setAuthorizedSignatory(updatedAuthorizedSignatory);

        // Get the file keys associated with this authorized signatory
        const fileKeysToRemove = [
            `AUTHORIZED_PROOF_OF_ADDRESS_${index}`,
            `AUTHORIZED_PROOF_OF_ID_BACK_${index}`,
            `AUTHORIZED_RES_PROOF_OF_ADDRESS_${index}`
        ];

        // Filter out the removed authorized signatory's files from imageList
        const filteredImageList = imageList.filter(item =>
            !fileKeysToRemove.includes(item.key)
        );

        // Update indices for remaining files after the removed authorized signatory
        const updatedImageList = filteredImageList.map(item => {
            const [prefix, currentIndex] = item.key.split('_').slice(-1)[0].split('_');
            if (parseInt(currentIndex) > index) {
                // Decrease the index by 1 for all items after the removed authorized signatory
                return {
                    ...item,
                    key: `${prefix}-${parseInt(currentIndex) - 1}`
                };
            }
            return item;
        });

        // Update the imageList with all changes
        setImageList(updatedImageList);
    };

    const handleFileChange = (index, field, file) => {
        const maxFileSize = 5 * 1024 * 1024; // 5 MB
        const allowedExtensions = ['jpg', 'jpeg', 'png', 'pdf', 'heic'];
        const fileExtension = file.name.split('.').pop().toLowerCase();

        if (file.size > maxFileSize) {
            alert('File size exceeds the limit of 5 MB.');
            return false;
        } else if (!allowedExtensions.includes(fileExtension)) {
            alert('Please upload files with extension jpg, jpeg, png, pdf, heic.');
            return false;
        }
        return true;
    };

    return (
        <>
            <div className="w-100">
                <Row>
                    <Col md={12} className="d-flex align-items-center gap-2">
                        <h5>Authorized Signatory Section</h5>
                        <Button color="primary" onClick={addAuthorizedSignatory}>+</Button>
                    </Col>
                </Row>
            </div>
            {authorizedSignatory?.map((authorizedSign, index) => (
                <div key={index} className="justify w-100" style={{ marginBlock: "10px" }}>
                    {index > 0 && (
                        <Button color="danger" onClick={() => removeAuthorizedSignatory(index)}>-</Button>
                    )}
                    <Row>
                        <Col md={6}>
                            <FormGroup>
                                <Label>Full Name</Label>
                                <Input className={"form-control " + ((!validationErrors.step4.fullName) ? 'is-valid' : 'is-invalid')}
                                    type="text"
                                    value={authorizedSign.fullName}
                                    onChange={(e) => handleInputChange(index, 'fullName', e.target.value)}
                                    placeholder="John"
                                    required
                                />
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <Label>Job Title</Label>
                                <Input className={"form-control " + ((!validationErrors.step4.jobTitle) ? 'is-valid' : 'is-invalid')}
                                    type="text"
                                    value={authorizedSign.jobTitle}
                                    onChange={(e) => handleInputChange(index, 'jobTitle', e.target.value)}
                                    placeholder="Mr/Mrs"
                                    required
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <FormGroup>
                                <Label>Email</Label>
                                <Input className={"form-control " + ((!validationErrors.step4.email || emailError === 0) ? 'is-valid' : 'is-invalid')}
                                    type="email"
                                    required
                                    placeholder="john@gmail.com"
                                    value={authorizedSign.email}
                                    invalid={emailError === 1}
                                    valid={emailError === 0}
                                    onChange={(e) => handleInputChange(index, 'email', e.target.value)}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={4}>
                            <FormGroup>
                                <Label>Proof of Identity</Label>
                                <Input
                                    className={"form-control " + (!validationErrors.step4.proofOfIdentity ? 'is-valid' : 'is-invalid')}
                                    type="file"
                                    accept=".jpg, .jpeg, .png, .pdf, .heic"
                                    onChange={(e) => {
                                        if (e.target.files[0] && handleFileChange(index, 'proofOfIdentity', e.target.files[0])) {
                                            updateListFunction(`AUTHORIZED_PROOF_OF_ADDRESS_${index}`, [e.target.files[0]]);
                                            handleInputChange(index, 'proofOfIdentity', e.target.files[0]);
                                        }
                                    }}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={4}>
                            <FormGroup>
                                <Label>Proof of Identity Back</Label>
                                <Input
                                    type="file"
                                    accept=".jpg, .jpeg, .png, .pdf, .heic"
                                    onChange={(e) => {
                                        if (e.target.files[0] && handleFileChange(index, 'proofOfIdentityBack', e.target.files[0])) {
                                            updateListFunction(`AUTHORIZED_PROOF_OF_ID_BACK_${index}`, [e.target.files[0]]);
                                            handleInputChange(index, 'proofOfIdentityBack', e.target.files[0]);
                                        }
                                    }}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={4}>
                            <FormGroup>
                                <Label>Residential Proof of Address</Label>
                                <Input
                                    type="file"
                                    accept=".jpg, .jpeg, .png, .pdf, .heic"
                                    onChange={(e) => {
                                        if (e.target.files[0] && handleFileChange(index, 'resProofOfAddress', e.target.files[0])) {
                                            updateListFunction(`AUTHORIZED_RES_PROOF_OF_ADDRESS_${index}`, [e.target.files[0]]);
                                            handleInputChange(index, 'resProofOfAddress', e.target.files[0]);
                                        }
                                    }}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </div>
            ))}
        </>
    );
};

export default AuthorizedSignatory;